@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700');


@darkblue:		#0d3050;
@lightblue:		#3aadd9;
@red:			#ef4255;
@yellow:		#ffce55;
@darkgrey:		#666e79;
@lightgrey:		#f7f7f7;
@white:			#fff;

@body-font-color:	@darkgrey;
@body-font-family:	'Source Sans Pro', Arial, Helvetica, sans-serif;

.font-size(@sizeValue) {
	@pxValue: (@sizeValue);
	@remValue: (@sizeValue / 10);
	@remLine: @remValue * 1.5;
	font-size: ~"@{sizeValue}px"; 
	font-size: ~"@{remValue}rem";
	line-height: @sizeValue * 1.5px;
	line-height: ~"@{remLine}rem";
}

