.or-divider {
	padding-top: 25px;
	padding-bottom: 25px;

	.line {
		display: block;
		position: relative;
		top: 15px;
		float: left;
		width: 43%;
		height: 1px;
		background: rgba(0,0,0,0.2);
	}

	.text {
		display: block;
		color: #999;
		float: left;
		width: 14%;
		.font-size(18);
		text-align: center;
	}
}

.forgot-password {
	padding-top: 25px;
	padding-bottom: 25px;
	text-align: center;
}

.form {
	padding-top: 50px;
}

label {
	padding-top: 5px;
	font-weight: normal;
}

.form-check {
	padding-left: 15px;
}

.button-row {
	padding-top: 25px;
	padding-left: 15px;

	.btn {
		color: @white;
		text-transform: uppercase;
		background-color: @lightblue;
		border: 0;
	}
}

body#page-login {
	.form { padding-top: 0; }

	.button-row {
		padding-left: 0;

		&.signin-default {
			padding-top: 10px;
		}
	}

	button.new-account {
		color: @white;
		background-color: @darkblue;
	}

	button.signin-fb {
		background-color: #3b5998;

		.fa { padding-right: 10px; }
	}
}