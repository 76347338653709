@import 'bootstrap/mixins.less';

body {
	font-family: @body-font-family;
	color: @darkgrey;
	.font-size(16);
	background-color: @lightgrey;
}

h1, h2, h3, h4, h5, h6 {
	color: @darkblue;
	font-weight: 700;
}

h1 { .font-size(36); }
h2 { .font-size(30); }
h3 { .font-size(24); }
h4 { .font-size(18); }
h5 { .font-size(16); }
h6 { .font-size(16); }

a:hover,
a:visited,
a:link,
a:active
{
    text-decoration: none;
}

.required { color: @red; }

.vcenter-box {
	display:table;
}
.vcenter {
	display:table-cell;
	vertical-align:middle;
	float:none;
}

.no-gutter {
    padding-right:0;
    padding-left:0;
}


header {
	nav {
		background-color: @white;

		&.navbar-fixed-top {
			border: 0;
			z-index: 99999; /* overlap bxslider 9999*/
		}

		.navbar-header {
			.navbar-brand {
				height: auto;
			}

			.navbar-toggle {
				margin-top: 30px;
				background-color: @white;
				border: 1px solid @lightgrey;

				.icon-bar {
					background-color: @lightblue;
				}
			}
		}

		.navbar-nav {
			.nav-pills > li.active > a,
			.nav-pills > li.active > a:hover,
			.nav-pills > li.active > a:focus
			{
				color: @lightblue;
				background-color: @white;
			}

			li > ul,
			.open .dropdown-menu
			{
				margin: 0;
				padding: 0;
				padding-top: 10px;
				padding-bottom: 10px;
				list-style: none;
				.font-size(16);
				background-color: @white;
			}

			.dropdown-menu > li > a,
			.dropdown-menu > li > a:hover
			{
				color: @lightblue;
			}

			li.active ul li > a,
			.dropdown-menu > li > a:hover,
			.dropdown-menu > li > a:focus,
			.dropdown-menu > .active > a,
			.dropdown-menu > .active > a:hover,
			.dropdown-menu > .active > a:focus
			{
				color: @red;
				background-color: transparent;
			}

			.fa { padding-left: 5px; }
		}
	}
}

main { //main body

	padding-top: 80px;

	#slideshow-home {
		background-color: @white;

		ul.bxslider {
			li img {
				display: block;
				margin: 0 auto;
			}
		}
	}

	.section,
	.gallery
	{

		&.white { background-color: @white; }

		h2 { line-height: 3rem; }

		&.new-songs,
		&.promotions
		{
			padding-top: 30px;
			padding-bottom: 50px;

			.heading {
				padding-bottom: 3%;
				text-align: center;

				h3 {
					margin-bottom: 0;
					font-weight: 400;
				}
			}

			a { display: block; }
		}

		.thumbnail {
			background-color: @lightgrey;
			border-color: @lightblue;

			-webkit-transition: all 0.5s ease-in-out;
			-moz-transition: all 0.5s ease-in-out;
			-ms-transition: all 0.5s ease-in-out;
			-o-transition: all 0.5s ease-in-out;
			transition: all 0.5s ease-in-out;

			.poster {
				position: relative;

				.status {
					position: absolute;
					text-align: center;
					width: 100%;
					height: 100%;
					padding-top: 18.5%;
					color: @white;
					font-size: 24px;
					text-transform: uppercase;

					&.redeemed { background: rgba(58, 173, 217, 0.65); }
					&.expired { background: rgba(13, 48, 80, 0.65); }
					&.voided { background: rgba(239, 66, 85, 0.65); }
				}
			}

			.caption {
				text-align: center;
			}
		}

		.thumbnail:hover {
			background-color: @white;
			position: relative;
			z-index: 5;

			-webkit-box-shadow: 0 0 25px 0 rgba(25,25,25,0.5);
			box-shadow: 0 0 25px 0 rgba(25,25,25,0.5);

			-moz-transform: scale(1.10);
			-webkit-transform: scale(1.10);
			-o-transform: scale(1.10);
			-ms-transform: scale(1.10);
			transform: scale(1.10);
		}

		.button-box {
			line-height: 170px;
			text-align: center;
			background-color: @lightblue;
			border-radius: 4px;
			-webkit-border-radius: 4px;

			a { color: @white; }
		}

		.pagination-section {
			text-align: right;
		}
	}

	table.transaction.table-hover > tbody > tr:hover {
		background-color: @white;
	}
}

footer {
	.outlets {
		padding-bottom: 50px;
		background-color: @white;

		.container { padding-top: 20px; }

		.outlet-list {
			padding-top: 20px;

			ul {
				margin: 0;
				padding: 0;
				list-style-type: none;

				li {
					display: inline-block;

					a {
						display: block;
						padding: 10px 0 10px 25px;
						background-image: url('/assets/backend/css/images/bullet.png');
						background-position: 0 -30px;
						background-repeat: no-repeat;
						border-bottom: 1px solid @lightblue;
					}

					&.active a,
					a:hover
					{
						color: @red;
						background-position: 0 15px;
						border-bottom: 1px solid @red;
					}
				}
			}
		}
	}
	
	#social-bar {
		padding-top: 15px;
		padding-bottom: 15px;
		color: @white;
		text-align: center;
		background-color: @lightblue;

		.fa { .font-size(32); }

		a {
			display: inline-block;
			color: @white;
		}

		a:hover { color: @darkblue; }

		ul {
			margin: 0;
			padding: 0;
			list-style-type: none;

			li {
				display: inline-block;
				padding-right: 25px;
			}

			li:last-child { padding-right: 0; }
		}

		#app-download {
			h5 {
				color: @white !important;
				text-transform: uppercase;
				letter-spacing: 0.02em;
			}
		}
	}

	#feedback-footer {
		.content { padding-top: 3%; }

		a { text-decoration: underline; }
	}

	#copyright {
		padding-bottom: 3%;
		.font-size(12);
		text-transform: uppercase;
	}
}


body#page-home {
	footer {
		.outlets .container {
			border-top: 1px solid #ccc;
		}		
	}
}

body#page-content {
	main {
		.content {
			padding-top: 50px;
			padding-bottom: 50px;
		}
	}
}

body#page-content-promotion {
	main {
		.heading {
			.fa {
				padding-right: 6px;
				font-size: 270%;
			}

			h2 { display: inline-block; }
		}

		.content {
			padding-bottom: 50px;
		}
	}
}

body#page-gallery {
	main {
		.gallery {
			padding-bottom: 50px;
		}
	}
}

body#page-outlet {
	main {
		padding-bottom: 50px;

		h2 { line-height: 5.5rem; }

		h3 {
			color: @red;
			.font-size(16);
			text-transform: uppercase;
		}

		.btn {
			padding: 15px;
			color: @white;
			text-align: left;
			white-space: normal;
			background: rgb(102,110,121); /* Old browsers */
			background: -moz-linear-gradient(top,  rgba(102,110,121,1) 0%, rgba(67,74,84,1) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top,  rgba(102,110,121,1) 0%,rgba(67,74,84,1) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom,  rgba(102,110,121,1) 0%,rgba(67,74,84,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#666e79', endColorstr='#434a54',GradientType=0 ); /* IE6-9 */
			border: 0;
		}

		.address-directions,
		.address,
		.directions
		{
			padding-top: 15px;
			padding-bottom: 15px;

			a:link { text-decoration: underline; }
		}

		.address-directions { border-bottom: 1px solid #ccc; }

		.booking,
		.operationhours,
		.rooms-prices
		{
			.fa { padding-right: 6px; }

			p {
				margin-bottom: 5px;
				padding-left: 25px;
			}
		}
	}
}

body#page-login {
	main {
		.heading,
		.create-account
		{
			text-align: center;
		}

		.signin-account,
		.create-account
		{
			padding-top: 30px;
			padding-bottom: 30px;
		}

		.create-account {
			border-top: 1px solid #ccc;

			h2 {
				.font-size(36);
			}
		}

		.button-row {
			.btn.signin-fb {
				background: #3b5998;
			}
		}
	}
}


/* Mobile portrait */
@media (min-width: @screen-xs-min) {   }

/* Mobile landscape 560px and up */
@media (min-width: 560px) {
	main {
		.section {
			.button-box { line-height: 8rem; }
		}
	}
}

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	header {
		nav .navbar-nav {
			margin-top: 25px;
			li > a {
				padding-top: 10px;
				padding-bottom: 10px;
				margin-right: 10px;
			}

			li > ul > li > a {
				padding-top: 5px;
				padding-bottom: 5px;
			}
		}
	}

	main {
		.section {
			.button-box {
				line-height: 2.5rem;
				height: 116px;
				padding-top: 0;
			}
		}
	}

	footer {
		.outlets {
			.outlet-list { padding-top: 40px; }
		}

		#social-bar {
			text-align: left;

			#social-links {
				margin-bottom: 0;
			}
			#app-download {
				text-align: right;
			}
		}
	}
}


/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
	main {
		.section {
			.button-box {
				height: 144px;
			}
		}
	}
}


/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
	main {
		#slideshow-home {
			max-width: 1400px;
			margin: 0 auto;
			text-align: center;
		}

		.section {
			.button-box {
				height: 170px;
			}
		}
	}
}